import React, { useEffect, useState } from "react";
import { Box, Button, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import moment from "moment/moment";
import { styled } from '@mui/system';
import { demoFaceLogin, demoAddImage, demoSearchImage } from "../../data/face_detection/FaceDetectionServices";
import ImageCropper from "./ImageCropper";

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '8px',
    '.MuiButton-root': {
      textDecoration: 'none',
    },
});

const FormBody = styled('div')({
    display: 'contents',
    'div .displayImage': {},
    'div .deleteIcon': {
      position: 'absolute',
      marginLeft: '-25px',
      color: 'red',
      cursor: 'pointer',
    },
    'div .deleteIcon:hover': {
      backgroundColor: 'lightgray',
    },
});


const DemoFaceModule = () => {

    const [error, setError] = useState(null);
    const [username, setUsername] = useState("bv001");
    const [password, setPassword] = useState("abc123");
    const [accessToken, setAccessToken] = useState("");
    const [file, setFile] = useState(null);
    const [cam, setCam] = useState("1");
    const [dateTime, setDateTime] = useState(moment().format("yyyy-MM-DD HH:mm:ss"));
    const [files, setFiles] = useState([]);
    const [analyzeDatas, setAnalyzeDatas] = useState([]);
    const [analyzing, setAnalyzing] = useState(false);
    const [analyzingItem, setAnalyzingItem] = useState(-1);
    const [fn, setFn] = useState("add");
    const [searchImage, setSearchImage] = useState(null);

    const [processSearch, setProcessSearch] = useState(false);
    const [searchData, setSearchDatas] = useState([]);
    const [searchDataImages, setSearchDataImages] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const [type, setType] = useState("");
    const [full_name, setFullName] = useState("");
    const [gender, setGender] = useState("");
    

    const handleDangNhap = () => {
        demoFaceLogin(username, password).then((res) => {
            setAccessToken(res.data.accessToken)
        }).catch((err) => {
            console.log(err);
            setError(err);
        }).finally(() => {
        })
    }

    const loginForm = () => (
        <FormBody id="form-body">
            <table style={{width: "100%"}}>
                <tr>
                    <td style={{width: "100%"}}>
                        <div style={{textAlign: "right", fontSize: "30px", color:"green", fontWeight: "bold", display: "none"}}>GREEN MAKE COMPANY LIMITED</div>
                        <div style={{textAlign: "right", fontSize: "30px", color:"green", display: "none"}}>PROFESSIONAL SOFTWARE CONSULTANT AND DEVELOPING</div>
                    </td>
                    <td><img src="/logo192.png" alt="Upload" height="100px" /></td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <Typography fullWidth textAlign="center" component="h6" variant="h6" style={{color:"red"}}>
                            MÔ PHỎNG HỆ THỐNG NHẬN DẠNG GƯƠNG MẶT
                        </Typography>
                        <Typography fullWidth textAlign="center" component="h6" variant="h6" style={{color:"red"}}>
                            ỨNG DỤNG CÔNG NGHỆ TRÍ TUỆ NHÂN TẠO AI
                        </Typography>
                    </td>
                </tr>
            </table>
            <Root>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <TextField
                                value={username}
                                onChange={event => setUsername(event.target.value)}
                                fullWidth
                                margin="normal"
                                id="username"
                                label="Tên đăng nhập"
                                name="username"
                                autoComplete="username"
                                required
                                autoFocus
                            />
                            <TextField
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                                fullWidth
                                margin="normal"
                                id="password"
                                label="Mật khẩu"
                                name="password"
                                autoComplete="current-password"
                                type="password"
                                required
                            />

                            <Button variant="contained" onClick={handleDangNhap} >
                                Đăng nhập
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </Root>
            <div style={{padding:"20px"}}>
                <Typography fullWidth style={{color:"green", fontSize: "25px"}}>
                    Ghi chú:
                </Typography>
                <Typography fullWidth style={{color:"green", padding:"10px 20px 10px 20px"}}>
                    - Click chọn “Đăng nhập” để vào màn hình mô phỏng nhận dạng gương mặt. Vui lòng đừng thay đổi thông tin – bạn sẽ không đăng nhập được.
                </Typography>
                <Typography fullWidth style={{color:"green", padding:"10px 20px 10px 20px"}}>
                    - Sau khi thực hiện mô phỏng, bạn có góp ý hoặc yêu cầu tích hợp sử dụng sản phẩm AI này cho ứng dụng của bạn, hãy liên hệ với Công ty TNHH Tạo Xanh để được tư vấn giải pháp phù hợp.
                </Typography>
                <Typography fullWidth style={{color:"green", padding:"10px 20px 10px 20px"}}>
                    - Sản phẩm này có thể ứng dụng cho đa nền tảng Windows, Linux, Android, IOS hoặc lập trình vi xử lý bo mạch (ESP8266, raspberry). Mỗi một mô hình ứng có có mô hình giải pháp khác nhau, đáp ứng mọi nhu cầu dù nhỏ nhất của bạn.
                </Typography>
                <Typography fullWidth style={{color:"green", padding:"10px 20px 10px 20px"}}>
                    Người liện hệ: Mr Hiền, HP: 0939 66 05 06
                </Typography>
                <Typography fullWidth style={{color:"green", padding:"10px 20px 10px 20px"}}>
                    Xin cảm ơn và mong nhận được sự quan tâm, hợp tác.
                </Typography>
                <Typography fullWidth style={{color:"green", padding:"10px 20px 10px 20px"}}>
                    Trân trọng kính chào.
                </Typography>
            </div>
        </FormBody>
    )
    useEffect(() => {
        if(analyzingItem >= files.length){
            setAnalyzing(false);
        }
        if(0 > analyzingItem || analyzingItem >= files.length) {
            setAnalyzingItem(-1);
            setFiles([]);
            return;
        }

        var item = {};
        item.startTime = moment().format("HH:mm:ss.SSS");
        const fileItem = files[analyzingItem]
        demoAddImage(fileItem['file'].file, fileItem['type'], fileItem['full_name'], fileItem['gender'], accessToken).then((res) => {
            item.file = fileItem;
            if(res.data && res.data.face_locations.length > 0){
                item.face_locations = res.data.face_locations;
            }else{
                item.face_locations = [];
            }
            setAnalyzeDatas([...analyzeDatas, item]);
            setAnalyzingItem(analyzingItem + 1);
        }).catch((err) => {
            console.log(err);
            item.face_locations = [];
            setAnalyzeDatas([...analyzeDatas, item]);
            setAnalyzingItem(analyzingItem + 1);
        }).finally(() => {
        })
    }, [analyzingItem])

    useEffect(() => {
        if(!processSearch){
            return;
        }
        setNotFound(false);
        var item = {};
        demoSearchImage(searchImage['file'], accessToken).then((res) => {
            item.file = searchImage;
            if(res.data && res.data.data){
                var data = JSON.parse(res.data.data);
                if(data && data.result_matched.length > 0){
                    item.result_matched = data.result_matched;
                }else{
                    item.result_matched = [];
                    setNotFound(true);
                }
                // if(res.data && res.data.all_image.length > 0){
                //     item.all_image = res.data.all_image;
                // }else{
                //     item.all_image = [];
                // }
            }else{
                item.result_matched = [];
                setNotFound(true);
            }
            
            setSearchDatas(item);
            setProcessSearch(false);
        }).catch((err) => {
            console.log(err);
            item.result_matched = [];
            setSearchDatas(item);
            setProcessSearch(false);
        }).finally(() => {
        })
    }, [processSearch])

    const handleTextCam = () => event => {
        setCam(event.target.value);
    }

    const handleTextDateTime = () => event => {
        setDateTime(event.target.value);
    }

    const handleTextFullName = () => event => {
        setFullName(event.target.value);
    }

    const handleTextType = () => event => {
        setType(event.target.value);
    }

    const handleTextGender = () => event => {
        setGender(event.target.value);
    }

    const handleFileUpload = () => event => {
        let file = event.target.files[0];
        if(file == undefined){
            return;
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            setFile({value: reader.result, file: file});
        };

        reader.readAsDataURL(file);
    }

    const handleSearch= () => event => {
        let file = event.target.files[0];
        if(file == undefined){
            return;
        }
        const reader = new FileReader();

        reader.onloadend = () => {
            setSearchImage({value: reader.result, file: file});
        };

        reader.readAsDataURL(file);
    }

    const handleAddBtn = () => event => {
        if(!type || type == ""){
            alert("Hãy chọn loại");
            return;
        }
        if(!full_name || full_name == ""){
            alert("Hãy nhập tên");
            return;
        }
        if(!gender || gender == ""){
            alert("Hãy chọn giới tính");
            return;
        }
        if(!file){
            alert("Hãy chọn file");
            return;
        }
        setFiles([...files, {'type': type, 'full_name': full_name, 'gender': gender, 'file': file}]);
        // setCam("1");
        // setDateTime(moment().format("yyyy-MM-DD HH:mm:ss"));
        setType("");
        setFullName("");
        setGender(""); 
        setFile(null);
    }

    const handleAnalyze = () => {
        setAnalyzing(true);
        setAnalyzingItem(0);
        setAnalyzeDatas([]);
    }

    const handleSearchImage = () => {
        setProcessSearch(true);
        setSearchDatas([]);
    }

    const handleDeleteImage = (file) =>{
        const pos = files.indexOf(file)
        const newItems = files.filter((item, ind) => ind !== pos);
        setFiles(newItems);
    }

    const menu = () => (
        <FormBody id="form-body" 
        style={{textAlign: "right",width: "100%", display: "inline-block",height: "30px"}}>
            {fn != 'add' && <Button 
                    variant="contained" 
                    component="span"
                    style={{backgroundColor: "red"}}
                    onClick={e=>setFn('add')}>
                    Thêm khuôn mặt
                </Button>
            }
            {fn != 'search' && <Button 
                    variant="contained" 
                    component="span"
                    style={{backgroundColor: "red"}}
                    onClick={e=>setFn('search')}>
                    Tìm khuôn mặt
                </Button>
            }
        </FormBody>
    )

    const formAddImageToDB = () => (
        <FormBody id="form-body">
            <div style={{textAlign: "center"}}>MÔ PHỎNG NHẬP (THÊM) KHUÔN MẶT (FACE)</div>
            <div style={{textAlign: "center"}}>VÀO DỮ LIỆU MÁY CHỦ</div>
            <table>
                <tr>
                    <td>Tên:</td>
                    <td>
                        <input 
                            id="full_name" 
                            name="full_name"
                            type="text" 
                            value={full_name}
                            onChange={handleTextFullName()} />
                    </td>
                </tr>
                <tr>
                    <td>Loại:</td>
                    <td>
                        <select id="type" name="type" value={type} onChange={handleTextType()}>
                            <option value="">Chọn loại</option>
                            <option value="whitelist">White List</option>
                            <option value="blacklist">Black List</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Giới tính:</td>
                    <td>
                        <select id="gender" name="gender" value={gender} onChange={handleTextGender()}>
                            <option value="">Chọn giới tính</option>
                            <option value="Nam">Nam</option>
                            <option value="Nữ">Nữ</option>
                            <option value="Không rõ">Không rõ</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>File</td>
                    <td>
                        <label htmlFor="upload-image">
                            <Button variant="contained" component="span">
                                Chọn đường dẫn thêm hình
                            </Button>
                            <input
                                id="upload-image"
                                disabled={files.length>=10}
                                hidden
                                accept="image/*"
                                type="file"
                                onChange={handleFileUpload()}
                            />
                        </label>
                        {file && <span>{file['file']['name']}</span>}
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <Button 
                            variant="contained" 
                            component="span"
                            onClick={handleAddBtn()}>
                            Thêm vào danh sách hình
                        </Button>
                    </td>
                </tr>
            </table>
            <br/>
            <Grid container spacing={2}>
                {files.map(file => {
                    return <Grid style={{clear:"both"}} item xs={2.4}>
                        <div><img className="displayImage" src={file['file']['value']} alt="Upload" style={{maxWidth: "100%"}}/>
                        <DeleteForeverIcon className="deleteIcon" onClick={e=>handleDeleteImage(file)}></DeleteForeverIcon></div>
                    </Grid>
                })}
            </Grid>
            <LoadingButton fullWidth loading={analyzing} variant="contained" onClick={handleAnalyze} style={{height: "50px"}}>
                Nhận dạng khuôn mặt và thêm vào dữ liệu máy chủ
            </LoadingButton>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>
                        Kết quả nhận dạng bằng AI:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Hình
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>
                        KQ nhận dạng
                    </Typography>
                </Grid>
                {analyzeDatas.map(item => {
                    return (<>
                            <Grid item xs={3}>
                                {item['file'] != null && <img src={item['file']['file']['value']} alt="Img" style={{maxWidth: "100%"}}/>}
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container spacing={2}>
                                    {item['face_locations'].map(location => {
                                        let div = `<div style="background-image: url('${item['file']['file']['value']}'); background-position: -${location[3]}px -${location[0]}px; width: ${location[1] - location[3]}px; height: ${location[2] - location[0]}px;"></div>`;

                                        return (<>
                                                {false && <Grid item xs={1} dangerouslySetInnerHTML={{ __html: div }}>
                                                </Grid>}
                                                <Grid item xs={2} >
                                                    <ImageCropper img_path={encodeFileNameInURL(item['file']['file']['value'])} location={location} max_size={100}></ImageCropper>
                                                </Grid>
                                            </>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </>)
                        
                })}
            </Grid>
        </FormBody>
    )

    const searchImageContent = () => (
        <FormBody id="form-body">
            <div style={{textAlign: "center"}}>MÔ PHỎNG TÌM KHUÔN MẶT (FACE)</div>
            <div style={{textAlign: "center"}}>TỪ DỮ LIỆU MÁY CHỦ</div>
            <table>
                <tr>
                    <td>Chọn hình (có 1 khuôn mặt)</td>
                    <td>
                        <label htmlFor="upload-image">
                            <Button variant="contained" component="span">
                                Chọn hình
                            </Button>
                            <input
                                id="upload-image"
                                hidden
                                accept="image/*"
                                type="file"
                                onChange={handleSearch()}
                            />
                        </label>
                    </td>
                </tr>
            </table>
            <br/>
            <Grid container spacing={2}>
                {searchImage && <Grid style={{clear:"both"}} item xs={2.4}>
                        <div><img className="displayImage" src={searchImage['value']} alt="Upload" style={{maxWidth: "100%"}}/></div>
                    </Grid>
                }
            </Grid>
            <LoadingButton fullWidth loading={processSearch} variant="contained" onClick={handleSearchImage} style={{height: "50px"}}>
                Bắt đầu thực hiện
            </LoadingButton>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>
                        Kết quả tìm kiếm bằng AI:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Hình
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>
                        KQ nhận dạng
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>
                        Score
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography>
                        Loại
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Tên
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography>
                        Giới tính
                    </Typography>
                </Grid>
                {searchData && searchData.result_matched && searchData.result_matched.map(item => {
                    return (<>
                            <Grid item xs={3}>
                                {item['payload']['img_path'] != null && <img src={encodeFileNameInURL(item['payload']['img_path'])} alt="Img" style={{width: "100%", maxWidth: "100%"}}/>}
                            </Grid>
                            <Grid item xs={2} >
                                <img src={encodeFileNameInURL(item['payload']['img_face_path'])} alt="Img" style={{width: "100%", maxWidth: "100%"}}/>
                                <img src={encodeFileNameInURL(item['img_face_path'])} alt="Img" style={{width: "100%", maxWidth: "100%"}}/>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>
                                    {(item['score']*100).toFixed(2)}%
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography>
                                    {item['payload']['type']}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    {item['payload']['name']}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography>
                                    {item['payload']['gender']}
                                </Typography>
                            </Grid>
                        </>)
                })}
                {notFound && <Grid xs={12}>
                    Không tìm thấy
                </Grid>}
                {searchData && searchData.all_image && <Grid item xs={12} style={{backgroundColor: '#c8e6c9'}}>
                        <Typography>
                            Danh sách các hình có trong dữ liệu (max 100 hình)
                        </Typography>
                    </Grid>
                }
                {searchData && searchData.all_image && searchData.all_image.map(item => {
                    return <Grid item xs={2}>
                        <Typography>
                            <img src={encodeFileNameInURL(item)} alt="Img" style={{width: "100%", maxWidth: "100%"}}/>
                        </Typography>
                    </Grid>
                })}
            </Grid>
        </FormBody>
    )

    

    return (
        <>
            {(accessToken == null || accessToken == "") && loginForm()}
            {accessToken != null && accessToken != "" && menu()}
            {accessToken != null && accessToken != "" && fn == "add" && formAddImageToDB()}
            {accessToken != null && accessToken != "" && fn == "search" && searchImageContent()}
        </>
    );
}

function encodeFileNameInURL(url) {
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];
    const encodedFileName = encodeURIComponent(fileName);
    urlParts[urlParts.length - 1] = encodedFileName;
    const encodedURL = urlParts.join('/');
    return encodedURL;
}

function cropImage(imageSrc, location, cropWidth) {
    let [top, right, bottom, left] = location;
    let width = right - left;
    let height = bottom - top;
    const percent = cropWidth/width;
    top = Math.round(top * percent);
    left = Math.round(left * percent);
    width = Math.round(width * percent);
    height = Math.round(height * percent);
    let div = `<div style="background: url('${imageSrc}') no-repeat; background-size: ${Math.round(percent*100)}%; background-position: -${left}px -${top}px; width: ${cropWidth}px; height: ${height}px;">&nbsp;</div>`;

    return div;
}

export default DemoFaceModule;